import React, { useEffect, useState } from 'react'

import "./styles.scss"

export default function TemplateMessageView({
    components = []
}) {
    const formatMessage = (text = "") => {
        return <div>{text.split("\\n").map(part => <>{part}<br /></>)}</div>
    }

    return (
        <div className="template-message-view">
            {components.map((component, index) =>
                <div key={index}>
                    {component.type == "HEAD" &&
                        <div className="body-message">{component.text}</div>
                    }

                    {component.type == "BODY" &&
                        <div className="body-message">{formatMessage(component.text)}</div>
                    }
                </div>
            )}
        </div>
    )
}