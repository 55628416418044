import React, { useEffect, useState } from 'react';
import Loading from '../../Loading';
import { getResource } from '../../../services/utils';

import "./styles.scss"
import Button from '../../Button';
import CRM from '../../../services/crm';

export default function StatusChoice({
    value = [],
    setValue,
    next
}) {
    const [status, setStatus] = useState([])

    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        getStatus()
    }, [])

    const getStatus = async () => {
        try {
            setIsLoading(() => true)

            let { token } = await getResource({ key: CRM.resourceKey })
            let status = await CRM.getStatus(token)
            setStatus(status)
        } catch (error) {

        } finally {
            setIsLoading(() => false)
        }
    }

    const Option = ({ id, label, primaryColor = "#1C84C6", secondaryColor = "#FFFFFF" }) => (
        <div
            className="option"
            style={(value || []).includes(id) ?
                { backgroundColor: primaryColor, borderColor: primaryColor, color: secondaryColor } :
                { borderColor: primaryColor, color: primaryColor }
            }
            onClick={() => {
                value = value || []

                let action = value.includes(id) ? "unselect" : "select"

                let newValues = (action == "select") ? [...new Set([...value, id])] : value.filter(val => val != id)

                setValue(newValues)
            }}
        >
            {label}
        </div>
    )

    return (
        <div className="status-choice">
            {/* {JSON.stringify(value)} */}
            {isLoading ?
                <Loading

                /> :
                <>
                    <div className="status-list">
                        {status.map((option, index) => <Option key={index} {...option} />)}
                    </div>

                    <div className="footer">
                        <Button
                            text="Avançar"
                            onClick={next}
                        />
                    </div>
                </>
            }
        </div>
    )
}