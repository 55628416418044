import Agendor from "./agendor";
import Piperun from "./piperun";

const crmUsed = process.env.REACT_APP_CRM
const crmInstance = {
    piperun: Piperun,
    agendor: Agendor
}[crmUsed]

const CRM = {
    pluginName: crmInstance.pluginName,
    crmName: crmInstance.crmName,
    resourceKey: crmInstance.resourceKey,
    activeMessagesResourceKey: crmInstance.activeMessagesResourceKey,
    docUrl: crmInstance.docUrl,
    showWebhook: crmInstance.showWebhook,
    defaultStatus: crmInstance?.defaultStatus || (() => []),
    variablesTips: crmInstance?.variablesTips || (() => []),
    middlewareForBlipService: crmInstance.middlewareForBlipService,

    validToken: crmInstance.validToken,
    getPipelines: crmInstance.getPipelines,
    getStages: crmInstance.getStages,
    getTriggers: crmInstance.getTriggers,
    getStatus: crmInstance.getStatus,
    getWebhook: crmInstance.getWebhook || (() => null)
}

export default CRM