import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import RoutesPath from '../../constants/routes-path';

import { showToast, startLoading, stopLoading } from '../../services/common-service'

import settings from '../../config';

import Header from './components/Header';
import api from '../../factory/api';
import { getApplicationDataAsync } from '../../services/application-service';
import { getLoggedUserAsync } from '../../services/user-service';
import CardInstance from '../../components/cards/CardInstance';
import { generateAuthorizationToken, getResource, setResource } from '../../services/utils';
import CardConnect from '../../components/cards/CardConnect';
import CardApresentation from '../../components/cards/CardApresentation';
import Copyright from '../../components/Copyright';
import CardToken from '../../components/CardToken';
import BlipPortalToastTypes from '../../constants/blip-portal-toast-types';
import ErrorLoad from '../../components/ErrorLoad';
import CommandsList from '../../components/CommandsList';
import Start from '../../components/Start';
import Pipelines from '../../components/Pipelines';
import CustomTab from '../../CustomTab';
import CodeEditor from '../../components/CodeEditor';
import ResourcesList from '../../components/ResourcesList';
import Resource from '../../components/Resource';


import { IframeMessageProxy } from 'iframe-message-proxy'
import IMPContainer from '../../constants/iframe-message-proxy-container';
import CRMSetup from '../../components/CRMSetup';
import CRMActiveMessages from '../../components/CRMActiveMessages';
import { getInstance } from '../../services/middlewareForBlip';
import CRM from '../../services/crm';

const PAGE_ICON = 'plugin';
const BLANK = '_blank';

const Home = () => {
    const history = useHistory();
    const { t, i18n } = useTranslation();

    const [appInfo, setAppInfo] = useState({});
    const [user, setUser] = useState({});
    const [language, setLanguage] = useState('');

    const [isLoading, setIsLoading] = useState(true)

    const [error, setError] = useState(null)

    const [customTab, setCustomTab] = useState(null)

    const [resourceOpened, setResourceOpened] = useState(null)

    const [connectionEdit, setConnectionEdit] = useState(null)
    const [showEditButton, setShowEditButton] = useState(false)

    const [instance, setInstance] = useState(null)
    const [active, setActive] = useState()

    // const { data } = useFetch(`/instances`);

    useEffect(() => {
        // if (appInfo != {} && user != {})
        if (appInfo?.tenantId && user?.culture) { }
    }, [appInfo, user])

    useEffect(() => {
        getAppDataAsync()
    }, [])

    const getAppDataAsync = async () => {
        const app = await getApplicationDataAsync();
        setAppInfo(app);

        console.log("appInfo", app);

        const loggedUser = await getLoggedUserAsync();
        setUser(loggedUser);
        console.log("loggedUser", loggedUser)

        setLanguage(i18n.language);

        start({
            appInfo: app,
            user: loggedUser
        })
    };

    const start = async ({ appInfo, user }) => {
        try {
            let { token, namespace, active = true } = await getResource({ key: CRM.resourceKey }) || {}

            setActive(active)

            let instance;

            try {
                instance = await getInstance({
                    Authorization: generateAuthorizationToken(appInfo),
                    tenantId: appInfo.tenantId,
                    language: user.culture,
                    service: CRM.middlewareForBlipService
                })

                setInstance(instance)
            } catch (error) {
                if (error?.response?.data?.notFound) {
                } else {
                    showToast({
                        type: BlipPortalToastTypes.DANGER,
                        message: error?.message || "Ocorreu um erro"
                    })
                }
            }

            if (!token || !namespace || !instance)
                setConnectionEdit(true)
            else {
                let isValid = await CRM.validToken(token)
                setConnectionEdit(!isValid)
                setShowEditButton(true)
            }
        } catch (error) {
            showToast({
                type: BlipPortalToastTypes.DANGER,
                message: error?.message || "Ocorreu um erro"
            })
        } finally {

        }
    }

    const changeActive = async (active) => {
        try {
            startLoading()
            setActive(active)

            let resource = await getResource({ key: CRM.resourceKey }) || {}

            resource = { ...resource, active }

            await setResource({ key: CRM.resourceKey, type: "application/json", resource })

            showToast({
                type: BlipPortalToastTypes.SUCCESS,
                message: `Extensão ${active ? "ativada" : "desativada"} com sucesso!`
            })
        } catch (error) {
            showToast({
                type: BlipPortalToastTypes.DANGER,
                message: error?.message || "Ocorreu um erro"
            })
        } finally {
            stopLoading()
        }
    }

    return (
        <div className="flex" style={{ height: "100vh", overflowX: "auto" }}>
            <div className="ph1 ph4-m ph5-ns pb5 relative" style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                <Header
                    title={CRM.pluginName}
                    icon={PAGE_ICON}
                    onClick={() => window.open(CRM.docUrl, BLANK)}
                    connectionEdit={connectionEdit}
                    setConnectionEdit={setConnectionEdit}
                    showEditButton={showEditButton}
                    changeActive={changeActive}
                    active={active}
                />

                <div style={{ flex: 1 }}>
                    {connectionEdit == true ?
                        <CRMSetup
                            appInfo={appInfo}
                            user={appInfo}
                            language={language}
                            setConnectionEdit={setConnectionEdit}
                            setShowEditButton={setShowEditButton}
                            instance={instance}
                            setInstance={setInstance}
                        /> :
                        connectionEdit == false ?
                            <CRMActiveMessages
                                instance={instance}
                            /> :
                            <></>
                    }
                </div>

                <Copyright />
            </div>

            <CustomTab
                opened={customTab != null}
                setCustomTab={setCustomTab}
                {...customTab}
            />
        </div>
    );
};

Home.propTypes = {};

export default Home;
