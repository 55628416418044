import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../../../components/PageHeader';
import Button from '../../../../components/Button';
import CopyToClipboard from 'react-copy-to-clipboard';
import { showToast } from '../../../../services/common-service';
import BlipPortalToastTypes from '../../../../constants/blip-portal-toast-types';
import Switch from '../../../../components/Input/Switch';

const Header = ({
    title,
    icon,
    onClick,
    setConnectionEdit,
    showEditButton,
    active,
    changeActive
}) => {
    const { t } = useTranslation();

    const renderOptions = () => (
        <div className="ml3 flex" style={{ gap: 10, alignItems: "center" }}>
            {showEditButton &&
                <>
                    <div>
                        <Switch
                            name="active"
                            onChange={changeActive}
                            checked={active}
                        />
                    </div>

                    <Button
                        icon="edit"
                        variant="secondary"
                        onClick={() => setConnectionEdit(value => !value)}
                    />
                </>
            }

            <Button
                text={t('button.document')}
                icon="link"
                variant="ghost"
                onClick={() => onClick()}
                dataTestId="repository-link"
            />
        </div>
    );

    return (
        <PageHeader
            title={title}
            icon={icon}
            relatedOptions={renderOptions()}
        />
    );
};

Header.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.string,
    onClick: PropTypes.func
};

export default Header;
