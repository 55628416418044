import React, { useEffect, useState } from 'react';

import "./styles.scss"
import Button from '../../Button';
import CRM from '../../../services/crm';

export default function TriggersChoice({
    value,
    setValue,
    next
}) {
    const [triggers, setTriggers] = useState([])

    useEffect(() => {
        getTriggers()
    }, [])

    const getTriggers = async () => {
        let triggers = await CRM.getTriggers()
        setTriggers(triggers)
    }

    return (
        <div className="triggers-choice">
            <div className="help-buttons">
                <Button size={"short"} onClick={() => setValue(triggers)} disabled={(value || []).length == triggers.length}>Marcar todos</Button>
                <Button size={"short"} onClick={() => setValue([])} disabled={(value || []).length == 0}>Desmarcar todos</Button>
            </div>

            <div className="triggers-list">

                {triggers.map(({ value: trigger, label }, index) =>
                    <div
                        key={index}
                        className="option"
                        onClick={() => {
                            value = value || []

                            let action = value.includes(trigger) ? "unselect" : "select"

                            let newValues = (action == "select") ? [...new Set([...value, trigger])] : value.filter(val => val != trigger)

                            setValue(newValues)
                        }}
                        data-active={(value || []).includes(trigger)}
                    >
                        {label}
                    </div>
                )}
            </div>

            <div className="footer">
                <Button
                    text="Avançar"
                    onClick={value.length == 0 ? null : next}
                    disabled={value.length == 0}
                />
            </div>
        </div>
    )
}