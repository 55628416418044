import React, { useState, useEffect } from 'react';
import "./styles.scss";

export default function CustomTab({
    opened = false,
    headerComponent,
    tabs = [],
    reload = []
}) {
    const [tabSelected, setTabSelected] = useState("")

    useEffect(() => {
    }, [opened, ...reload])

    useEffect(() => {
        if (opened && tabs.length > 0) {
            setTabSelected(tabs[0]?.id)
        }
    }, [opened])

    useEffect(() => {
        if (opened) {
            document.querySelector(`[group=sidebar-tab-${tabSelected}]`)?.click()
        }

        let validationIfHasSelected = document.querySelector(`.bds-tab--selected`)
        if (!validationIfHasSelected || (Array.isArray(validationIfHasSelected) && validationIfHasSelected.length == 0))
            document.getElementsByClassName(`bds-tab`)?.[0]?.click()
    }, [tabSelected])

    if (!opened)
        return <></>

    return (
        <div className="custom-tab">
            <bds-tab-group className="hydrated" style={{ height: "100%" }}>
                <div className="header">
                    <bd-tabs className="bds-tabs bds-tabs--center hydrated">
                        <div className="ph4 pt2 z-3">
                            {headerComponent}

                            <div className="bds-tabs__header">
                                {tabs.map(tab => (
                                    <bds-tab
                                        key={tab.id}
                                        group={`sidebar-tab-${tab.id}`}
                                        label={tab.label}
                                        className={`bds-tab hydrated`}
                                        onClick={() => setTabSelected(tab.id)}
                                        tabindex={0}
                                    >
                                    </bds-tab>
                                ))}
                            </div>
                        </div>
                    </bd-tabs>
                </div>

                <div className="content" style={{ flex: 1 }}>
                    {/* {tabs.map((tab, index) =>
                        <bds-tab-item key={tab.id} number-element={index} open={tabSelected == tab.id}>
                            {tab.component}
                        </bds-tab-item>
                    )} */}
                    {tabs.find(tab => tab.id == tabSelected)?.component}
                </div>
            </bds-tab-group>
        </div>
    )
}