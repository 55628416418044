const translations = {
    success: {
        loaded: 'Carregado com sucesso',
        connect: 'Banco de dados cadastrado com sucesso'
    },
    error: {},
    warning: {},
    info: {}
};

export default translations;
