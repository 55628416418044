import { IframeMessageProxy } from "iframe-message-proxy"
import IMPContainer from '../constants/iframe-message-proxy-container';

export const generateAuthorizationToken = ({ accessKey, shortName }) => {
    try {
        return `Key ${btoa(`${shortName}:${atob(accessKey)}`)}`
    } catch (error) {
        console.error(error)
        return ""
    }
}

export const getResource = async ({
    key,
    handleError = true
}) => {
    try {
        const result = await IframeMessageProxy.sendMessage({
            action: IMPContainer.Actions.SEND_COMMAND,
            content: {
                command: {
                    method: IMPContainer.CommandMethods.GET,
                    uri: `/resources/${key}`
                }
            }
        });

        return result.response
    } catch (error) {
        if (handleError) {
            if (typeof error == "string") {
                error = JSON.parse(error)
                if (error?.message && typeof error.message == "string")
                    error.message = JSON.parse(error.message)
            }

            if (error?.code == 67 || error?.message?.reason?.code == 67)
                return null
        }

        throw error
    }
}

export const setResource = async ({
    key,
    resource,
    type
}) => {
    try {
        await IframeMessageProxy.sendMessage({
            action: IMPContainer.Actions.SEND_COMMAND,
            content: {
                command: {
                    method: IMPContainer.CommandMethods.SET,
                    uri: `/resources/${key}`,
                    type,
                    resource
                }
            }
        });
    } catch (error) {
        throw error
    }
}

export const getMessageTemplates = async () => {
    try {
        let result = await IframeMessageProxy.sendMessage({
            action: IMPContainer.Actions.SEND_COMMAND,
            content: {
                command: {
                    method: IMPContainer.CommandMethods.GET,
                    to: "postmaster@wa.gw.msging.net",
                    uri: "/message-templates?status=APPROVED"
                }
            }
        });

        console.log(result.response?.data.filter(({components}) => components.some(component => component.format == "DOCUMENT")))

        return result.response?.data
    } catch (error) {
        throw error
    }
}