import React, { useEffect, useState } from 'react'

import "./styles.scss"
import { showToast, startLoading, stopLoading } from '../../services/common-service'
import BlipPortalToastTypes from '../../constants/blip-portal-toast-types'
import { getResource, setResource } from '../../services/utils'
import CreateActiveMessage from '../CreateActiveMessage'
import Alert from '../Alert'
import Card from '../Card'
import Button from '../Button'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import Switch from '../Input/Switch'
import CRM from '../../services/crm'

export default function CRMActiveMessages({
    instance
}) {
    const { t } = useTranslation();

    const [mode, setMode] = useState("list")

    const [activeMessages, setActiveMessages] = useState([])
    const [activeMessageOpened, setActiveMessageOpened] = useState(null)

    const [alertOpened, setAlertOpened] = useState(false)
    const [alert, setAlert] = useState({})

    const [link] = useState(instance?.pipeline?._id ? `https://middlewareforblip.blip.sharkdev.com.br/${instance?.pipeline?._id}` : "")

    useEffect(() => {
        start()
    }, [])

    useEffect(() => {
        if (mode == "list" && activeMessageOpened) {
            setActiveMessageOpened(null)
        }
    }, [mode])

    useEffect(() => {
        if (activeMessageOpened)
            setMode("create")
        else
            setMode("list")
    }, [activeMessageOpened])

    const start = async () => {
        try {
            startLoading()

            let { items } = await getResource({ key: CRM.activeMessagesResourceKey }) || { items: [] }

            if (!items || !Array.isArray(items))
                items = []

            setActiveMessages(items)
        } catch (error) {
            showToast({
                type: BlipPortalToastTypes.DANGER,
                message: error?.message || "Ocorreu um erro"
            })
        } finally {
            stopLoading()
        }
    }

    const remove = async (_id) => {
        try {
            startLoading()

            let { items } = await getResource({ key: CRM.activeMessagesResourceKey }) || { items: [] }

            if (!items || !Array.isArray(items))
                items = []

            items = items.filter(item => item._id != _id)

            await setResource({ key: CRM.activeMessagesResourceKey, type: "application/json", resource: { items } })
            setActiveMessages(items)
        } catch (error) {
            showToast({
                type: BlipPortalToastTypes.DANGER,
                message: error?.message || "Ocorreu um erro"
            })
        } finally {
            stopLoading()
            setAlert({})
            setAlertOpened(false)
        }
    }

    const changeActive = async (_id, active) => {
        try {
            startLoading()

            let { items } = await getResource({ key: CRM.activeMessagesResourceKey }) || { items: [] }

            if (!items || !Array.isArray(items))
                items = []

            for (let item of items) {
                if (item._id == _id) {
                    item.active = active
                    break
                }
            }

            await setResource({ key: "piperunPlugin_ActiveMessages", type: "application/json", resource: { items } })
            setActiveMessages(items)
        } catch (error) {
            showToast({
                type: BlipPortalToastTypes.DANGER,
                message: error?.message || "Ocorreu um erro"
            })
        } finally {
            stopLoading()
        }
    }

    return (
        <div className="crm-active-messages-container">
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                    {CRM.showWebhook &&
                        <>
                            <bds-typo variant="fs-16" bold="semi-bold">Link para Webhook:</bds-typo>

                            <CopyToClipboard
                                text={link}
                                onCopy={() =>
                                    showToast({
                                        type: BlipPortalToastTypes.SUCCESS,
                                        title: t('messages.copy_to_clipboard'),
                                        message: t('messages.copy_to_clipboard')
                                    })
                                }
                            >
                                <div style={{ borderRadius: 15, display: 'flex', backgroundColor: "#DBF1F3", padding: "10px 20px", cursor: "pointer" }}>
                                    <input style={{ flex: 1, margin: 0, color: "#15afb2", color: "#15afb2", backgroundColor: "transparent", border: 0, cursor: "pointer" }} disabled={true} value={link} />

                                    <div>

                                    </div>
                                </div>
                            </CopyToClipboard>
                        </>
                    }
                </div>
                <div>
                    {mode != "list" &&
                        <Button
                            variant="ghost"
                            text="Voltar"
                            onClick={() => setMode("list")}
                        />
                    }

                    {(mode == "list" && activeMessages.length > 0) &&
                        <Button
                            text="Cadastrar"
                            onClick={() => setMode("create")}
                        />
                    }
                </div>
            </div>

            {(mode == "list" && activeMessages.length == 0) &&
                <div style={{ textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center", marginTop: 40 }}>
                    <bds-typo variant="fs-24" bold="semi-bold">Nenhuma mensagem ativa cadastrada</bds-typo>
                    <bds-typo variant="fs-24" bold="bold">Faça já seu primeiro cadastro</bds-typo>

                    <Button
                        text="Cadastrar minha primeira mensagem ativa"
                        onClick={() => setMode("create")}
                    />
                </div>
            }

            {(mode == "list" && activeMessages.length > 0) &&
                <bds-paper>
                    <div className="min-h-18 items">
                        <table>
                            <tbody>
                                <tr>
                                    {["Funil", "Etapa", "Mensagem ativa", "Parâmetros"].map((text, index2) =>
                                        <th key={index2} style={{ textAlign: "left" }}>
                                            <bds-typo variant="fs-16" bold="bold">{text}</bds-typo>
                                        </th>
                                    )}
                                    <th></th>
                                </tr>
                                {activeMessages.map((item, index) =>
                                    <tr key={index} className="card-resource">
                                        <td>
                                            <bds-typo variant="fs-16">{item.pipeline?.name}</bds-typo>
                                            {/* {JSON.stringify(item)} */}
                                        </td>
                                        <td>
                                            <bds-typo variant="fs-16">{item.stage?.name}</bds-typo>
                                        </td>
                                        <td>
                                            <bds-typo variant="fs-16">{item.template?.name}</bds-typo>
                                        </td>
                                        <td>
                                            <bds-typo variant="fs-16"><i>{item.components.map(component => component.parameters.length).reduce((acc, curr) => acc + curr, 0)} parâmetros</i></bds-typo>
                                        </td>
                                        <td style={{ display: "flex", justifyContent: "flex-end" }}>
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
                                                    <div className="none">
                                                        <Button
                                                            variant="ghost"
                                                            icon="trash"
                                                            arrow={false}
                                                            disabled={false}
                                                            onClick={() => {
                                                                setAlertOpened(() => true)
                                                                setAlert({
                                                                    header: "Atenção!",
                                                                    body: "Tem certeza que deseja deletar este item? Você não conseguirá recuperá-lo depois.",
                                                                    actions: [
                                                                        {
                                                                            variant: "secondary",
                                                                            bold: "true",
                                                                            title: "Cancelar",
                                                                            onClick: () => setAlertOpened(false)
                                                                        },
                                                                        {
                                                                            variant: "primary",
                                                                            bold: "true",
                                                                            title: "Sim, tenho certeza",
                                                                            onClick: async () => {
                                                                                remove(item._id)
                                                                            }
                                                                        }
                                                                    ]
                                                                })
                                                            }}
                                                            size="short"
                                                        />
                                                    </div>
                                                    <div className="none">
                                                        <Button
                                                            variant="ghost"
                                                            icon="edit"
                                                            arrow={false}
                                                            disabled={false}
                                                            onClick={() => setActiveMessageOpened(item)}
                                                        />
                                                    </div>

                                                    <Switch
                                                        name="active"
                                                        onChange={checked => changeActive(item._id, checked)}
                                                        checked={item.active}
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </bds-paper>
            }

            {mode == "create" &&
                <CreateActiveMessage
                    instance={instance}
                    defaultData={activeMessageOpened}
                    onCreate={() => {
                        setMode("list")
                        start()
                    }}
                />
            }

            <Alert
                opened={alertOpened}
                setAlert={setAlert}
                {...alert}
            />
        </div>
    )
}