import axios from "axios"

const baseUrl = "https://proxy.sharkdev.com.br/?url=https://api.agendor.com.br"

export const listAgendorWebhooks = async (token) => {
    try {
        if (!token)
            throw { message: "Token não informado" }

        let result = await axios.get(`${baseUrl}/integrations/subscriptions`, {
            headers: {
                Authorization: `Token ${token}`
            }
        })

        // if (!result?.data?.success)
        //     throw { message: "Ocorreu um erro ao buscar as etapas" }

        return result.data?.data
    } catch (error) {
        throw error
    }
}

export const createAgendorWebhook = async (token, { target_url, event }) => {
    try {
        if (!token)
            throw { message: "Token não informado" }

        let result = await axios.post(`${baseUrl}/integrations/subscriptions`, {
            target_url,
            event
        }, {
            headers: {
                Authorization: `Token ${token}`
            }
        })

        // if (!result?.data?.success)
        //     throw { message: "Ocorreu um erro ao buscar as etapas" }

        return result.data?.data
    } catch (error) {
        throw error
    }
}

export const deleteAgendorWebhook = async (token, id) => {
    try {
        if (!token)
            throw { message: "Token não informado" }

        let result = await axios.delete(`${baseUrl}/integrations/subscriptions/${id}`, {
            headers: {
                Authorization: `Token ${token}`
            }
        })

        // if (!result?.data?.success)
        //     throw { message: "Ocorreu um erro ao buscar as etapas" }

        return result.data?.data
    } catch (error) {
        throw error
    }
}