import React, { useEffect, useState } from 'react';
import Loading from '../../Loading';
import { getResource } from '../../../services/utils';

import "./styles.scss"
import CRM from '../../../services/crm';

export default function PipelineChoice({
    value,
    setValue
}) {
    const [pipelines, setPipelines] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        start()
    }, [])

    const start = async () => {
        try {
            setIsLoading(() => true)

            let { token } = await getResource({ key: CRM.resourceKey })
            let data = await CRM.getPipelines(token)

            setPipelines(data)
        } catch (error) {

        } finally {
            setIsLoading(() => false)
        }
    }

    return (
        <div className="pipeline-choice">
            {/* {JSON.stringify(value)} */}

            {isLoading &&
                <Loading

                />
            }

            {!isLoading &&
                <div className="pipeline-list">
                    {pipelines.map((pipeline, index) =>
                        <div key={index} className="option" onClick={() => setValue(pipeline)} data-active={pipeline.id == value?.id}>
                            {pipeline.name}
                        </div>
                    )}
                </div>
            }
        </div>
    )
}