import api from "../factory/api"
import CRM from "./crm"

// INSTANCES
export const getInstance = async (headers) => {
    try {
        let { data } = await api.get("/instances", { headers })
        return data.data
    } catch (error) {
        throw error
    }
}

export const createInstance = async (execution_token, headers) => {
    try {
        let { data } = await api.post("/instances", { execution_token, service: CRM.middlewareForBlipService }, { headers })
        return data.data
    } catch (error) {
        throw error
    }
}

// PIPELINES
export const createPipeline = async (body, headers) => {
    try {
        let { data } = await api.post("/pipelines", body, { headers })
        return data
    } catch (error) {
        throw error
    }
}