import React from 'react'

export default function Loading({
    color = "main",
    size = "standard"
}) {
    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <bds-loading-spinner
                color={color}
                size={size}
            />
        </div>
    )
}