import React from 'react'

export default function Copyright() {
    return (
        <div className="center flex justify-center mt3" style={{ gap: 10 }}>
            <p>Desenvolvido por</p>

            <a href="https://sharkdev.com.br" target="_blank">
                <img src="https://sharkdev.com.br/static/logos/logo_dark.svg" />
            </a>
        </div>
    )
}