import React, { useEffect, useState } from 'react'
import Card from '../Card/Card'
import Input from '../Input/Input'
import Button from '../Button'
import { showToast, startLoading, stopLoading } from '../../services/common-service'
import BlipPortalToastTypes from '../../constants/blip-portal-toast-types'
import { generateAuthorizationToken, getResource, setResource } from '../../services/utils'
import { createInstance } from '../../services/middlewareForBlip'
import Switch from '../Input/Switch'
import DDIList from '../../data/ddi'

import "./styles.scss"
import Select from '../Input/Select'
import CRM from '../../services/crm'

export default function CRMSetup({
    appInfo,
    user,
    language,
    setConnectionEdit,
    setShowEditButton,
    instance,
    setInstance
}) {
    const [inputToken, setInputToken] = useState("")
    const [inputNamespace, setInputNamespace] = useState("")
    const [inputStandarizeDDI, setInputStandarizeDDI] = useState(false)
    const [inputDDI, setInputDDI] = useState("")
    const [active, setActive] = useState(true)

    const [edit, setEdit] = useState(false)

    const crmPluginResourceDefault = {
        token: "",
        namespace: "",
        standardizeDDI: false,
        active: true
    }

    useEffect(() => {
        start()
    }, [])

    useEffect(() => {
        if (!inputDDI)
            setInputDDI("+55")
    }, [inputDDI])

    const start = async () => {
        try {
            startLoading()

            let { token, namespace, standardizeDDI, DDI, active = true } = await getResource({ key: CRM.resourceKey }) || crmPluginResourceDefault

            if (token && namespace)
                setEdit(true)

            setInputToken(token || "")
            setInputNamespace(namespace || "")
            setInputStandarizeDDI(standardizeDDI || false)
            setInputDDI(DDI || "")
            setActive(active)
        } catch (error) {
            showToast({
                type: BlipPortalToastTypes.DANGER,
                message: error?.message || "Ocorreu um erro"
            })
        } finally {
            stopLoading()
        }
    }

    const submit = async () => {
        try {
            startLoading()

            await CRM.validToken(inputToken)

            let { DDI, ...newJson } = await getResource({ key: CRM.resourceKey }) || crmPluginResourceDefault

            await setResource({
                key: CRM.resourceKey,
                resource: {
                    ...newJson,
                    token: inputToken,
                    namespace: inputNamespace,
                    standardizeDDI: inputStandarizeDDI,
                    DDI: inputStandarizeDDI == true ? inputDDI : undefined,
                    active
                },
                type: "application/json"
            })

            if (!instance) {
                let instance = await createInstance(generateAuthorizationToken(appInfo), {
                    Authorization: generateAuthorizationToken(appInfo),
                    tenantId: appInfo.tenantId,
                    language: user.culture,
                    service: CRM.middlewareForBlipService,
                })

                setInstance(instance)
            }

            setConnectionEdit(false)
            setShowEditButton(true)
        } catch (error) {
            showToast({
                type: BlipPortalToastTypes.DANGER,
                message: error?.message || "Ocorreu um erro"
            })
        } finally {
            stopLoading()
        }
    }

    return (
        <div className="w-100 m0 mb3 mb4-l mt4">
            <div id="create-application-marketplace-step" class="tc">
                <div class="tagline-title-container">
                    <bds-typo variant="fs-20" tag="h4" margin="false" translate="" class="hydrated">{edit ? "Precisa alterar? Vamos lá!" : "Vamos começar"}</bds-typo>
                    <bds-typo variant="fs-32" tag="h2" translate="" class="hydrated">Informe os dados básicos</bds-typo>
                </div>
                <div className="w-50 center mt4">
                    <bds-paper class="relative paper__elevation--static hydrated mt4">
                        <Input
                            name="token"
                            label={`Token do ${CRM.crmName}`}
                            placeholder={`Informe o token do ${CRM.crmName}`}
                            value={inputToken}
                            onChange={e => setInputToken(e.target.value)}
                        />
                    </bds-paper>

                    <bds-paper class="relative paper__elevation--static hydrated mt3">
                        <Input
                            name="token"
                            label="Namespace"
                            placeholder="Namespace"
                            value={inputNamespace}
                            onChange={e => setInputNamespace(e.target.value)}
                        />
                    </bds-paper>

                    <bds-paper class="relative paper__elevation--static hydrated mt3">
                        <div style={{ display: 'flex', flexDirection: "row", alignItems: "center", justify: "space-between", padding: 10 }}>
                            <div style={{ flex: 1, textAlign: "left" }}>
                                <bds-typo variant="fs-16" tag="h2" translate="" className="hydrated">Padronizar DDI quando não houver no contato</bds-typo>
                            </div>
                            <div style={{ display: 'flex', alignItems: "center", gap: 10 }}>
                                {inputStandarizeDDI &&
                                    <div>
                                        {/* <InputPhoneNumber
                                        name="ddi"
                                        label="DDI"
                                        value={inputDDI}
                                        onChange={e => setInputDDI(e.target.value)}
                                    /> */}
                                        <Select
                                            name="ddi"
                                            options={DDIList}
                                            value={inputDDI}
                                            onChange={e => setInputDDI(e.target.value)}
                                        />
                                    </div>
                                }
                                <div>
                                    <Switch
                                        name="standarizeDDI"
                                        checked={inputStandarizeDDI}
                                        onChange={value => setInputStandarizeDDI(value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </bds-paper>

                    <div className="w-100 flex justify-center mt4">
                        {edit &&
                            <div className="mr3">
                                <Button
                                    text="Cancelar"
                                    onClick={() => setConnectionEdit(false)}
                                />
                            </div>
                        }

                        <Button
                            text="Avançar"
                            disabled={inputToken.length != 36 || inputNamespace.length != 36}
                            onClick={submit}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}