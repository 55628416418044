import React, { useEffect, useState } from 'react';
import Loading from '../../Loading';
import { getResource } from '../../../services/utils';

import "./styles.scss"
import CRM from '../../../services/crm';

export default function StageChoice({
    pipeline,
    value,
    setValue
}) {
    const [stages, setStages] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        start()
    }, [])

    const start = async () => {
        try {
            setIsLoading(() => true)

            if (!pipeline)
                return;

            let { token } = await getResource({ key: CRM.resourceKey })
            let data = await CRM.getStages(token, pipeline.id)

            setStages(data)
        } catch (error) {

        } finally {
            setIsLoading(() => false)
        }
    }

    return (
        <div className="stage-choice">
            {/* {JSON.stringify(value)} */}

            {isLoading &&
                <Loading

                />
            }

            {!isLoading &&
                <div className="stage-list">
                    {stages.map((stage, index) =>
                        <div key={index} className="option" onClick={() => setValue(stage)} data-active={stage.id == value?.id}>
                            {stage.name}
                        </div>
                    )}
                </div>
            }
        </div>
    )
}