import React, { useState, useEffect } from 'react';

import Input from '../Input/Input';
import PipelineChoice from './PipelineChoice';
import StageChoice from './StageChoice';
import MessageTemplate from './MessageTemplate';
import { getResource, setResource } from '../../services/utils';
import { v4 as uuid } from 'uuid';
import { showToast, startLoading, stopLoading } from '../../services/common-service';
import BlipPortalToastTypes from '../../constants/blip-portal-toast-types';
import StatusChoice from './StatusChoice';
import TriggersChoice from './TriggersChoice';
import CRM from '../../services/crm';

export default function CreateActiveMessage({
    instance,
    defaultData,
    onCreate
}) {
    const [pipeline, setPipeline] = useState(null)
    const [stage, setStage] = useState(null)
    const [triggers, setTriggers] = useState([])
    const [status, setStatus] = useState(CRM.defaultStatus())//[0, 1, 3])
    const [template, setTemplate] = useState(null)
    const [active, setActive] = useState(true)

    const [steps, setSteps] = useState([
        {
            id: "pipeline",
            active: true,
            text: "Funil",
        },
        {
            id: "stage",
            text: "Etapa",
            component: <h1>Etapa</h1>,
            disabled: true
        },
        {
            id: "trigger",
            text: "Gatilho",
            component: <h1>Gatilho</h1>,
            disabled: true
        },
        {
            id: "status",
            text: "Situação",
            component: <h1>Situação</h1>,
            disabled: true
        },
        {
            id: "template",
            text: "Template",
            component: <h1>Template</h1>,
            disabled: true
        }
    ])

    useEffect(() => {
        if (defaultData) {
            setPipeline(defaultData.pipeline)
            setStage(defaultData.stage)
            setTemplate({
                ...defaultData.template,
                components: defaultData.components
            })
            setActive(defaultData.active)
            setTriggers(defaultData.triggers)
            setStatus(defaultData.status)

            setSteps(steps => {
                for (let step of steps) {
                    step.active = false
                    step.completed = true
                    step.disabled = false
                }

                steps[steps.length - 1].active = true

                return [...steps]
            })
        }
    }, [])

    const goToStep = (step, stepToDone, removeDisabled = null) => {
        if (removeDisabled == null && steps[step].disabled)
            return;

        setSteps(steps => {
            steps.map((st) => st.active = false)
            steps[step].active = true
            if (stepToDone != undefined)
                steps[stepToDone].completed = true
            if (removeDisabled != null)
                steps[removeDisabled].disabled = false
            return [...steps]
        })
    }

    const submit = async ({ template, components }) => {
        try {
            startLoading()

            let { token } = await getResource({ key: CRM.resourceKey })

            // let webhook = []
            let webhook = await CRM.getWebhook(
                token,
                {
                    instance,
                    pipeline,
                    stage,
                    triggers,
                    status,
                    active,
                    webhook: defaultData?.webhook || null
                }
            )

            let obj = {
                _id: defaultData ? defaultData._id : uuid(),
                pipeline: {
                    id: pipeline.id,
                    name: pipeline.name
                },
                stage: {
                    id: stage.id,
                    name: stage.name
                },
                triggers,
                status,
                template,
                components,
                active,
                webhook
            }

            // console.log(obj)

            let { items } = await getResource({ key: CRM.activeMessagesResourceKey }) || { items: [] }

            if (defaultData) {
                for (let i = 0; i < items.length; i++) {
                    if (items[i]._id == obj._id) {
                        items[i] = obj
                        break
                    }
                }
            } else if (items.some(item => item?.pipeline?.id == obj?.pipeline?.id && item?.stage?.id == obj?.stage?.id && item?.template?.name == obj?.template?.name))
                throw { message: "Não é possível realizar este cadastro pois encontramos um outro cadastro com funil, etapa e template idênticos." }
            else
                items.push(obj)

            await setResource({
                key: CRM.activeMessagesResourceKey,
                resource: { items },
                type: "application/json"
            })

            showToast({
                type: BlipPortalToastTypes.SUCCESS,
                message: "Mensagem ativa cadastrada com sucesso!"
            })

            if (onCreate)
                onCreate()
        } catch (error) {
            showToast({
                type: BlipPortalToastTypes.DANGER,
                message: error?.message || "Ocorreu um erro"
            })
        } finally {
            stopLoading()
        }
    }

    return (
        <div>
            <bds-paper>
                <bds-stepper>
                    {steps.map((step, index) =>
                        <bds-step
                            key={index}
                            index={index}
                            onClick={() => goToStep(index)}
                            pointer={step.pointer == undefined ? true : step.pointer}
                            {...step}
                        >
                            {step.text}
                        </bds-step>
                    )}
                </bds-stepper>

                {steps.filter(step => step.active).map((step, index) =>
                    <div key={index}>
                        {step.id == "pipeline" &&
                            <PipelineChoice
                                value={pipeline}
                                setValue={value => {
                                    setPipeline(value)
                                    goToStep(1, 0, 1)
                                }}
                            />
                        }

                        {step.id == "stage" &&
                            <StageChoice
                                pipeline={pipeline}
                                value={stage}
                                setValue={value => {
                                    setStage(value)
                                    goToStep(2, 1, 2)
                                }}
                            />
                        }

                        {step.id == "trigger" &&
                            <TriggersChoice
                                value={triggers}
                                setValue={value => {
                                    setTriggers(value)
                                }}
                                next={() => {
                                    goToStep(3, 2, 3)
                                }}
                            />
                        }

                        {step.id == "status" &&
                            <StatusChoice
                                value={status}
                                setValue={value => {
                                    setStatus(value)
                                }}
                                next={() => {
                                    goToStep(4, 3, 4)
                                }}
                            />
                        }


                        {step.id == "template" &&
                            <MessageTemplate
                                pipeline={pipeline}
                                stage={stage}
                                value={template}
                                setValue={value => {
                                    setTemplate(value)
                                    // goToStep(2, 1)
                                    // console.log(value)
                                    submit(value)
                                }}
                            />
                        }
                    </div>
                )}
            </bds-paper>
        </div>
    )
}