import React, { useEffect, useState } from 'react';
import Loading from '../../Loading';
import { getMessageTemplates } from '../../../services/utils';
import { showToast, startLoading, stopLoading } from '../../../services/common-service'
import BlipPortalToastTypes from '../../../constants/blip-portal-toast-types';

import "./styles.scss"
import TemplateMessageView from '../../TemplateMessageView';
import Input from '../../Input/Input';
import Button from '../../Button'
import CRM from '../../../services/crm';

export default function MessageTemplate({
    pipeline,
    stage,
    value,
    setValue
}) {
    const [templates, setTemplates] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [selected, setSelected] = useState(null)

    const [variables, setVariables] = useState([])
    const [media, setMedia] = useState(null)

    const [changed, setChanged] = useState(false)

    const [tips, setTips] = useState([])

    useEffect(() => {
        start()

        setTips(CRM.variablesTips?.())
    }, [])

    const start = async () => {
        try {
            setIsLoading(() => true)

            if (!pipeline)
                return;

            let data = await getMessageTemplates()
            console.log(data)

            setTemplates(data)


            if (value) {
                let header = value.components.find(component => component.type == "header")
                let media = null;

                if (header?.parameters?.length > 0)
                    media = header.parameters[0]

                let body = value.components.find(component => component.type == "body")
                let variables = []

                if (body?.parameters?.length > 0)
                    variables = body.parameters.map(parameter => parameter.text)

                setMedia(media)
                setVariables(variables)
                setSelected(data.find(template => template.id == value?.id) || null)
            }
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(() => false)
        }
    }

    const countVariables = (inputString) => {
        // Regular expression to match variables in the input string
        const variableRegex = /{{\d+}}/g;

        // Use the match method with the regex to find all occurrences of variables
        const matches = inputString.match(variableRegex);

        // If no matches are found, return 0
        if (!matches)
            return 0;

        // Return the number of matches found, which represents the number of variables
        return matches.length;
    }

    const submit = async () => {
        try {
            startLoading()
            setIsLoading(() => true)

            setValue({
                template: {
                    id: selected.id,
                    name: selected.name,
                    language: selected.language,
                    category: selected.category
                },
                components: [
                    {
                        type: "header",
                        parameters: media ? [media] : []
                    },
                    {
                        type: "body",
                        parameters: variables.map(variable => ({ type: "text", text: variable }))
                    }
                ]
            })
        } catch (error) {
            showToast({
                type: BlipPortalToastTypes.DANGER,
                message: error?.message || "Ocorreu um erro"
            });
        } finally {
            stopLoading()
            setIsLoading(() => false)
        }
    }

    const select = (selected) => {
        setSelected(selected)

        let body = selected?.components.find(component => component.type == "BODY")
        if (body && body.text) {
            setVariables(() => {
                let newArray = []
                for (let i = 0; i < countVariables(body.text); i++)
                    newArray.push("")

                return newArray
            })
        }
    }

    const Variable = ({ onChange, ...rest }) => (
        <div className="variable-area">
            <div className="tips-area">
                <div className="tips">
                    {tips.map((tip, index) =>
                        <Button
                            variant="ghost"
                            text={tip.text}
                            size="short"
                            onClick={() => onChange({ target: { value: tip.value } })}
                        />
                    )}
                </div>
            </div>

            <Input
                {...rest}
                onChange={onChange}
            />
        </div>
    )

    return (
        <div className="template-choice">
            {/* {JSON.stringify(value)} */}
            {isLoading &&
                <Loading

                />
            }

            {!isLoading &&
                <div className="structure">
                    <div className="list">
                        {templates.map((template, index) =>
                            <div key={index} className="option" onClick={() => select(template)} data-active={template.id == selected?.id}>
                                <div className="name">
                                    {template.name}
                                </div>
                                <div className="language">
                                    {template.language}
                                </div>
                            </div>
                        )}
                    </div>
                    {selected &&
                        <div className="informations">
                            <p><b>Categoria:</b> {selected.category}</p>
                            <p><b>Nome:</b> {selected.name}</p>

                            <TemplateMessageView
                                components={selected.components}
                            />

                            {[selected?.components
                                .find(component => ["DOCUMENT", "VIDEO", "IMAGE"].includes(component?.format))]
                                .filter(component => component)
                                .map((component) =>
                                    <div>
                                        <div>
                                            <p>Mídia</p>
                                            {/* {component ? JSON.stringify(component) : "kk"} */}
                                            <Input
                                                name="media"
                                                // label={`Variável ${index + 1}`}
                                                placeholder="Link da mídia"
                                                helperMessage="Link da mídia que deseja utilizar nessa mensagem ativa"
                                                value={media?.[component.format?.toLowerCase()]?.link}
                                                onChange={e => setMedia((media) => ({ type: component.format, [component.format?.toLowerCase()]: { ...media?.[component.format?.toLowerCase()], link: e.target.value } }))}
                                            />
                                        </div>

                                        {["DOCUMENT"].includes(component?.format) &&
                                            <div>
                                                <p>Nome do arquivo</p>
                                                {/* {component ? JSON.stringify(component) : "kk"} */}
                                                <Input
                                                    name="media"
                                                    // label={`Variável ${index + 1}`}
                                                    placeholder="Nome da mídia"
                                                    helperMessage="Nome da mídia que deseja utilizar nessa mensagem ativa"
                                                    value={media?.[component.format?.toLowerCase()]?.filename}
                                                    onChange={e => setMedia((media) => ({ type: component.format, [component.format?.toLowerCase()]: { ...media?.[component.format?.toLowerCase()], filename: e.target.value } }))}
                                                />
                                            </div>
                                        }
                                    </div>
                                )}

                            {variables.length > 0 &&
                                <div>
                                    <p>Variáveis</p>

                                    <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                                        {variables.map((variable, index) =>
                                            <Variable
                                                key={index}
                                                name={`variable_${index}`}
                                                placeholder={`{{${index + 1}}}`}
                                                value={variables[index]}
                                                onChange={e => setVariables(variables => {
                                                    variables[index] = e.target.value
                                                    return [...variables]
                                                })}
                                            />
                                        )}
                                    </div>
                                </div>
                            }

                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
                                <Button
                                    text="Salvar"
                                    onClick={submit}
                                    disabled={isLoading}
                                />
                            </div>
                        </div>
                    }
                </div>
            }
        </div >
    )
}